import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import servicesData from '../../assets/data/ServicesData';
import { Typography } from "antd";

const ServicesContainer = styled.div`
  padding: 50px 0;
  background-color: transparent;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

const ServiceGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ServiceCard = styled(animated.div)`
  width: 90%;
  background-color: rgb(0, 0, 0, 0.01);
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  order: ${({ alternate }) => (alternate ? -1 : 1)};
  font-family: 'Patua One', sans-serif;
  color:#fff !important;
  gap:20px;
`;

const ServiceImage = styled.img`
  max-width: 33%;
  height: auto;
  border-radius: 50%;
  padding:20px;
  @media (max-width: 800px) {
    max-width: 100%;
  font-size: 1rem; /* Smaller font size for small screens */
`;
const Text = styled(Typography.Paragraph)`
text-align:center;
 font-family: 'Patua One', sans-serif;
padding:20px;
color:white;
  font-size: 1.3rem;
  line-height: 1.6;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    font-size: 1rem; /* Smaller font size for small screens */
`;
const Title = styled.h2`
color:white;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align:center;
   font-family: 'Patua One', sans-serif;
color:white;
@media (max-width: 800px) {
  font-size: 1rem; /* Smaller font size for small screens */
`;


const Services = () => {


  return (
    <>
      <Title>Our Services</Title>
      <ServicesContainer>
        <ServiceGrid>
          {servicesData.map((service, index) => (
            <ServiceItem key={index} service={service} index={index} />
          ))}
        </ServiceGrid>
      </ServicesContainer>
    </>
  );
};

const ServiceItem = ({ service, index }) => {
  // Use the useSpring hook to create animations for each card
  const [props, set] = useSpring(() => ({
    opacity: 0,
    y: -10,
  }));

  // Use the useInView hook to detect when the card enters the viewport
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the card enters the viewport
    threshold: 0.8, // Trigger when 20% of the card is visible
  });

  // Update the animations when the card enters the viewport
  set({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : -10,

  });

  return (
    <animated.div key={index} style={props}>
      <ServiceCard alternate={index % 2 === 1} ref={ref} style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>
        {index % 2 === 0 && (
          <ServiceImage
            src={service.image}
            alt={service.title}
          />
        )}
        <div >
          <Title>{service.title}</Title><hr />
          <Text>{service.description}</Text>
        </div>
        {index % 2 === 1 && (
          <ServiceImage
            src={service.image}
            alt={service.title}
          />
        )}
      </ServiceCard>
    </animated.div>
  );
};

export default Services;
