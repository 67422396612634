import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { StarFilled } from "@ant-design/icons";

// Define your styled components outside of the component function
const Text = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: black;
`;

const Section = styled.div`
  margin-top: 0px;
  gap: 10px;
  position: static;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to bottom, #ffffff, #f2f2f2);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
  color: #333;
  width: 100%;
  box-sizing: border-box;
`;

const Stat = styled.div`
  flex: 1;
  margin: ${(props) => (props.isMobile ? "10px 0" : "0")};
  text-align: center;
`;

const AboutStats = () => {
  const [inView, setInView] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      setInView(true);
    } else {
      setInView(false);
    }
  };

  return (
    <div>
      <Section isMobile={isMobile}>
        <Stat isMobile={isMobile}>
          <Text>Customers Served</Text>
          <hr />
          <Text>
            <CountUp start={inView ? 0 : null} end={inView ? 1000 : null} duration={2} />
            <PlusOutlined />
          </Text>
        </Stat>
        <Stat isMobile={isMobile}>
          <Text>Cars Served</Text>
          <hr />
          <Text>
            <CountUp start={inView ? 0 : null} end={inView ? 1200 : null} duration={2} />
            <PlusOutlined />
          </Text>
        </Stat>
        <Stat isMobile={isMobile}>
          <Text>Reviewed Services</Text>
          <hr />
          <Text>
            <CountUp start={inView ? 0 : null} end={inView ? 500 : null} duration={2} />
            <PlusOutlined />
          </Text>
        </Stat>
        <Stat isMobile={isMobile}>
          <Text>
            <Text>Service Rating</Text>
            <VisibilitySensor onChange={handleVisibilityChange}>
              <div>
                <StarFilled style={{ color: "maroon", fontSize: "24px", marginRight: "4px" }} />
                <StarFilled style={{ color: "maroon", fontSize: "24px", marginRight: "4px" }} />
                <StarFilled style={{ color: "maroon", fontSize: "24px", marginRight: "4px" }} />
                <StarFilled style={{ color: "maroon", fontSize: "24px", marginRight: "4px" }} />
                <StarFilled style={{ color: "maroon", fontSize: "24px", marginRight: "4px" }} />
              </div>
            </VisibilitySensor>
          </Text>
        </Stat>
      </Section>
    </div>
  );
};

export default AboutStats;
