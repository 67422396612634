import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import businesslogo from "../../assets/images/cwhero.png";
import { Button, Typography } from "antd";
import { Link } from "react-scroll";
import ContactModal from "../ContactModal/ContactModal";

const AnimatedNavbarContainer = animated(styled.nav`
  position: static !important;
  z-index: 9999;
  background: linear-gradient(270deg, rgb(0, 0, 0), rgb(163, 0, 3));
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`);

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const BusinessName = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  color: white;
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    display: ${({ ismenuopen }) => (ismenuopen ? "flex" : "none")};
  }
`;

const NavItem = styled.li`
  margin: 0 10px;
  transition: transform 0.2s ease-in-out;
  font-weight: 300;

  &:hover {
    transform: translateY(2px);
    font-weight: 600;
  }

  @media (max-width: 768px) {
    margin: 5px 0;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: rgba(255, 255, 255, 0.5);
    translatey: -45deg 0.5s;
    font-weight: 600;
  }
`;

const ScheduleButton = styled(Button)`
  background-color: #fff;
  color: darkred;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin: 5px;
  border: 1px;

  &:hover {
    background-color: rgb(163,3,3) !important;
    color: white !important;
  }
`;
const PoweredByText = styled(Typography)`
display:absolute;
font-family: 'Patua One', sans-serif;
justify-content:center;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  top:0;
  background: linear-gradient(270deg, #ffaf11,  rgb(163, 3, 3));
  color: white;
  transition: transform 0.2s ease-in-out;
  font-weight:900;

  &:hover {
    transform: scale(1.1);
    color: black; // Change the color on hover
  }
`;
const MenuButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;

  @media (max-width: 768px) {
    display: block;
  }
`;

function Navbar() {
  const [ismenuopen, setmenuopen] = useState(false);
  const [isContactModalVisible, setContactModalVisible] = useState(true);


  const toggleMenu = () => {
    setmenuopen(!ismenuopen);
  };

  const toggleContactModal = () => {
    setContactModalVisible(!isContactModalVisible);
  };

  const navbarAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 300 },
  });
  const handlePoweredByTextClick = () => {
    // Use navigate to go to www.codejutsutech.com when powered by text is clicked
    window.open("https://www.codejutsutech.com", "_blank");
  };
  return (
    <>
      <PoweredByText onClick={handlePoweredByTextClick}>
        POWERED BY @ CODEJUTSUTECH<hr></hr>
        click to know more..
      </PoweredByText>

      <div style={{ position: "static" }}>

        <ContactModal
          visible={isContactModalVisible}
          onCancel={toggleContactModal}
          onSubmit={(values) => {
            console.log("Form submitted with values: ", values);
            toggleContactModal();
          }}
        />

        <AnimatedNavbarContainer style={navbarAnimation}>
          <LogoContainer>
            <LogoImage src={businesslogo} />
            <BusinessName>CJ CAR WASH</BusinessName>
          </LogoContainer>
          <NavList ismenuopen={ismenuopen}>
            <NavItem>
              <NavLink to="hero" smooth={true} duration={500}>
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="services" smooth={true} duration={500}>
                Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="about" smooth={true} duration={500}>
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="contact" smooth={true} duration={500}>
                Contact
              </NavLink>
            </NavItem>
          </NavList>
          <ScheduleButton onClick={toggleContactModal}>Book Now</ScheduleButton>
          <MenuButton onClick={toggleMenu}>&#9776;</MenuButton>
        </AnimatedNavbarContainer>
      </div>
    </>
  );
}

export default Navbar;
