import React from "react";
import { Layout, Row, Col } from "antd";
import { InstagramOutlined, FacebookOutlined, TwitterOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import styled from "styled-components";
import contactbg from '../../assets/images/23.png';
const { Footer } = Layout;

const FooterContainer = styled(Footer)`
background-image:url(${contactbg});
background-size:cover;
  color: white !important;
  text-align: center;
  font-family: 'Patua One', sans-serif;
  
`;

const FooterRow = styled(Row)`
padding:50px;
background:linear-gradient(145deg,rgb(0,0,0),#444,#111);
  margin-top: 20px;
  font-family: 'Patua One', sans-serif;
`;

const FooterCol = styled(Col)`
  padding: 20px;
  font-family: 'Patua One', sans-serif;
`;

const FooterHeading = styled.h2`
  font-size: 20px;
font-family: 'Patua One', sans-serif;
  color: white !important;
  
`;

const FooterContent = styled.p`
font-family: 'Patua One', sans-serif;
  font-size: 24;
  
`;

const SocialMediaLinks = styled.div`
  margin-top: 10px;
`;

const SocialLink = styled.a`
  
color: white !important;
font-size:2rem;
 
`;

const CopyrightText = styled.p`
padding:20px;
background:linear-gradient(45deg,#000,#222);
  
  font-family: 'Patua One', sans-serif;
  font-size: 1rem;
  
`;

function AppFooter() {
  const fadeInUp = {
    initial: { opacity: 0, translateY: 20 },
    animate: { opacity: 1, translateY: 0 },
  };

  return (
    <FooterContainer>
      <FooterRow gutter={[16, 16]}>
        <FooterCol xs={24} sm={12} md={8} lg={8}>
          <motion.div {...fadeInUp}>
            <FooterHeading>About Us</FooterHeading>
            <FooterContent>
              CJ Car Wash is your trusted partner for professional car cleaning and detailing services. With years of experience, we ensure your vehicle looks its best.
            </FooterContent>
          </motion.div>
        </FooterCol>
        <FooterCol xs={24} sm={12} md={8} lg={8}>
          <motion.div {...fadeInUp}>
            <FooterHeading>Locations</FooterHeading>
            <FooterContent>
              HYDERABAD<br />
              Phone: (123) 456-7890<br />
              Email: info@cjcarwash.com
            </FooterContent>
          </motion.div>
        </FooterCol>
        <FooterCol xs={24} sm={24} md={6} lg={6}>
          <motion.div {...fadeInUp}>
            <FooterHeading>Follow Us</FooterHeading>
            <SocialMediaLinks>
              <SocialLink href="#">
                <InstagramOutlined />
              </SocialLink>
              <SocialLink href="#">
                <FacebookOutlined />
              </SocialLink>
              <SocialLink href="#">
                <TwitterOutlined />
              </SocialLink>
            </SocialMediaLinks>
          </motion.div>
        </FooterCol>
      </FooterRow>
      <CopyrightText>&copy; 2023 CJ Car Wash. All rights reserved.</CopyrightText>
      <CopyrightText><b>poweredby©codejutsutech</b></CopyrightText>
    </FooterContainer>
  );
}

export default AppFooter;
