import service1 from '../../assets/images/1.png';
import service2 from '../../assets/images/2.png';
import service3 from '../../assets/images/3.png';
import service4 from '../../assets/images/4.png';
import service5 from '../../assets/images/5.png';
import service6 from '../../assets/images/6.png';
import service7 from '../../assets/images/7.png';
import service8 from '../../assets/images/8.png';

const servicesData = [
    {
        title: "Car Washing",
        description: "Our professional team provides top-notch car washing services to keep your vehicle clean and free from dirt, dust, and contaminants. Regular car washing not only enhances your car's appearance but also prevents corrosion and maintains its paintwork in excellent condition.",
        image: service1,
    },
    {
        title: "Interior Detailing",
        description: "We offer interior detailing to make your car look and feel brand new. Our comprehensive interior cleaning and detailing services ensure a fresh, hygienic, and comfortable interior environment. It helps remove allergens, odors, and bacteria, promoting a healthier driving experience.",
        image: service2,
    },
    {
        title: "Exterior Detailing",
        description: "Exterior detailing goes beyond regular washing to provide a shiny and protected vehicle exterior. It involves polishing, waxing, and protective coatings to safeguard your car's paint from UV rays, environmental contaminants, and minor scratches, preserving its resale value.",
        image: service3,
    },
    {
        title: "Waxing & Polishing",
        description: "Our waxing and polishing services maintain your car's shine and protect its exterior surfaces. Waxing provides a protective layer that repels dirt and contaminants, while polishing removes imperfections, restoring the paint's gloss and brilliance.",
        image: service4,
    },
    {
        title: "Tire Care",
        description: "We take care of your tires to ensure safety and longevity. Proper tire maintenance, including inflation, rotation, and alignment, enhances traction, improves fuel efficiency, and extends tire life. It also reduces the risk of blowouts and accidents.",
        image: service5,
    },
    {
        title: "Paint Protection",
        description: "Protect your car's paint with our advanced paint protection solutions. Our coatings create a barrier against harsh elements, such as UV rays, acid rain, and bird droppings, preventing paint damage and oxidation, and keeping your car looking new for longer.",
        image: service6,
    },
    {
        title: "Window Tinting",
        description: "Enhance privacy and reduce heat with our professional window tinting services. Tinted windows block harmful UV rays, reduce interior temperatures, and protect your car's interior from fading. They also provide added security and a sleek appearance.",
        image: service7,
    },
    {
        title: "Engine Cleaning",
        description: "Keep your engine clean and running smoothly with our engine cleaning services. A clean engine operates more efficiently, reducing fuel consumption and emissions. It also prevents overheating and extends the lifespan of engine components.",
        image: service8,
    },
];

export default servicesData;