import React from "react";
import HeroSection from "../HeroSection/HeroSection";
import Services from "../Services/Services";
import About from "../About/About";
import Contact from "../Contact/Contact";
import styled from 'styled-components';
import AboutUsStat from "../About/AboutStats"

// Define a global container style
const Container = styled.div`
position:static;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
`;

// Define section styles
const SectionStyle = styled.section`
  padding: 40px 0; /* Default padding */
  position:static;
  
  @media (max-width: 768px) {
    padding: 20px 0; /* Smaller padding on mobile devices */
  }
`;

function Home() {
  return (
    <Container>
      <SectionStyle id="hero">
        <HeroSection />
      </SectionStyle>
      <SectionStyle id="services">
        <Services />
      </SectionStyle>
      <SectionStyle id="about">
        <About />
      </SectionStyle>
      <AboutUsStat />
      <SectionStyle id="contact">
        <Contact />
      </SectionStyle>

    </Container>
  );
}

export default Home;
