import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Button, Col, Row, Form, Input, notification } from "antd";
import heroImage from "../../assets/images/cwhero.png";

function HeroSection() {
  // Define the spring animation for the right section
  const rightSectionAnimation = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(50px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    config: {
      tension: 170,
      friction: 20,
    },
  });

  // Define the spring animation for the image
  const imageAnimation = useSpring({
    from: {
      opacity: 0,
      transform: "translateX(-50px)",
    },
    to: {
      opacity: 1,
      transform: "translateX(0px)",
    },
    config: {
      tension: 170,
      friction: 20,
    },
  });

  // Define responsive font sizes
  const responsiveFontSizes = {
    xs: {
      title: "2rem",
      description: "0.8rem",
      button: "0.8rem",
    },
    sm: {
      title: "2.5rem",
      description: "1rem",
      button: "1rem",
    },
    md: {
      title: "3rem",
      description: "1.2rem",
      button: "1.2rem",
    },
    lg: {
      title: "3.5rem",
      description: "1.4rem",
      button: "1.4rem",
    },
  };

  const titleStyle = {
    fontWeight: "bold",
    color: "white",
    marginBottom: "1rem",
    fontSize: responsiveFontSizes.md.title, // Set initial font size for xs
  };

  const descriptionStyle = {
    marginBottom: "1rem",
    fontSize: responsiveFontSizes.md.description, // Set initial font size for xs
  };

  const buttonStyle = {
    fontSize: responsiveFontSizes.md.button, // Set initial font size for xs
    fontWeight: "bold",
    background: "black",
    color: "white",
  };

  // Define rightSectionStyle here
  const rightSectionStyle = {
    background: "transparent",
    color: "white",
    textAlign: "center",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
    height: "100%",
  };

  // Form submission handling
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = (values) => {
    // You can add your form submission logic here.
    // For now, let's simulate a successful submission.
    setTimeout(() => {
      setSubmitted(true);
      notification.success({
        message: "Form Submitted",
        description:
          "Thank you for submitting the form! We will contact you soon.",
      });
    }, 1000);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <animated.img
            src={heroImage}
            alt="Hero"
            style={{
              ...imageAnimation,
              width: "100%",
              height: "auto",
              background: "transparent",
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <animated.div style={{ ...rightSectionStyle, ...rightSectionAnimation }}>
            {submitted ? (
              <div>
                <h1 style={titleStyle}>Thank You!</h1>
                <p style={descriptionStyle}>
                  We have received your details and will contact you soon.
                  <br /> Explore our services meanwhile
                  <br />
                  <Button href="#services" style={buttonStyle}>
                    Explore Services
                  </Button>
                </p>
              </div>
            ) : (
              <div
                style={{
                  border: "10px dashed black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={titleStyle}>CJ CAR WASH</h1>
                <p style={descriptionStyle}>
                  Your Trusted Choice for Professional Car Cleaning and Detailing
                </p>
                <p style={descriptionStyle}>
                  Simply provide your name and phone number, and we'll take care
                  of the rest. We'll call you and schedule your car wash
                  appointment.
                </p>
                <Form form={form} onFinish={handleFormSubmit} style={{ width: "50%" }}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Please enter your name" }]}
                  >
                    <Input placeholder="Your Name" />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    rules={[{ required: true, message: "Please enter your phone number" }]}
                  >
                    <Input placeholder="Phone Number" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      style={buttonStyle}
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )}
          </animated.div>
        </Col>
      </Row>
    </>
  );
}

export default HeroSection;
