import React from "react";
import { Card, Form, Input, Button, Row, Col, Typography } from "antd";
import { MailOutlined, PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import styled from "styled-components";
import ContactModal from "../ContactModal/ContactModal";
import contactbg from '../../assets/images/contactbg1.png';

const { Title } = Typography;
const ContactContainer = styled.div`
  background-size: cover;
  background-color: #0000; /* Fallback background color */
  background-image: url(${contactbg});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 90vh;
  position: relative;
`;

const ContactTitle = styled(Title)`
  text-align: center;
  margin-bottom: 32px;
  font-family: 'Patua One', sans-serif;
  color: rgb(255, 255, 255) !important;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContactRow = styled(Row)`
  margin-bottom: 32px;
  width: 100%;
`;

const ContactCard = styled(Card)`
  width: 100%;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Patua One', sans-serif;
  color: black;
  justify-content: center;
  text-align: center;
  justify-items: center;

  .ant-card-head-title {
    color: rgb(160, 3, 3);
    font-size: 1rem;
  }
    

  & p {
    font-size: 1rem;
    margin: 8px 0;
    font-family: 'Patua One', sans-serif;
     @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  }
`;

const ContactFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  background-color: rgba(255, 255, 255, 0.5);
 
`;

const ContactForm = styled(Form)`
  padding: 20px;
  align-items: center;
  justify-content: center;
   font-size: 1rem;
   @media (max-width: 768px) {
    font-size: 0.5rem;
  }

`;

const ContactInput = styled(Input)`
  width: 30%;
  font-family: 'Patua One', sans-serif;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContactSubmitButton = styled(Button)`
  background-color: rgb(160, 3, 3);
  width: 30%;
  margin-top: 16px;
  
 @media (max-width: 768px) {
    width: 100%;
  }
   &:hover {
    
    background-color: rgb(0,3,3) !important;
    color: white !important;
  }
`;

const AdditionalInfoCard = styled(Card)`
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Patua One', sans-serif;
  color: black;

  .ant-card-head-title {
    color: rgb(160, 3, 3);
    font-size: 1rem;
  }

  & p {
    margin: 4px 0;
    font-family: 'Patua One', sans-serif;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
  }
`;

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeInOut" } },
};

const Contact = () => {
  const handleContactFormSubmit = (values) => {
    console.log("Form data submitted:", values);
  };

  return (
    <ContactContainer>
      <ContactTitle level={2}>Contact Us</ContactTitle>
      <ContactRow gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <InView>
            {({ inView, ref }) => (
              <motion.div
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={cardVariants}
                ref={ref}
              >
                <ContactCard title="Business Contact Info">
                  <p>
                    <EnvironmentOutlined /> Address: Gokul plots, KPHB
                  </p>
                  <p>
                    <PhoneOutlined /> Phone: (123) 456-7890
                  </p>
                  <p>
                    <MailOutlined /> Email: info@cjcarwash.com
                  </p>
                </ContactCard>
              </motion.div>
            )}
          </InView>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <InView>
            {({ inView, ref }) => (
              <motion.div
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={cardVariants}
                ref={ref}
              >
                <AdditionalInfoCard title="CJ Car Care">
                  <p>
                    CJ Car Care is dedicated to providing high-quality car care services. Regular car care is essential to maintain the appearance, performance, and value of your vehicle.
                  </p>
                  <p>
                    Our team of professionals is committed to delivering the best results for your car, ensuring it looks and feels brand new.
                  </p>
                </AdditionalInfoCard>
              </motion.div>
            )}
          </InView>
        </Col>
      </ContactRow>

      <ContactRow gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <InView>
            {({ inView, ref }) => (
              <motion.div
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={cardVariants}
                ref={ref}
              >
                <ContactFormWrapper>
                  <ContactCard title="Contact Form" style={{ background: '#00011111', fontSize: '1rem' }}>
                    <ContactForm onFinish={handleContactFormSubmit}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your name",
                          },
                        ]}
                      >
                        <ContactInput placeholder="Name" />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your phone number",
                          },
                        ]}
                      >
                        <ContactInput placeholder="Phone Number" />
                      </Form.Item>
                      <Form.Item>
                        <ContactSubmitButton type="primary" htmlType="submit">
                          Submit
                        </ContactSubmitButton>
                      </Form.Item>
                    </ContactForm>
                  </ContactCard>
                </ContactFormWrapper>
              </motion.div>
            )}
          </InView>
        </Col>
      </ContactRow>
      <ContactModal />
    </ContactContainer>
  );
};

export default Contact;
