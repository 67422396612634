import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import styled from 'styled-components';
import Typography from 'antd/es/typography/Typography';
import ModalIMage from '../../assets/images/contactbg1.png';

const CustomModal = styled(Modal)`
  .ant-modal-content {
background-image:url(${ModalIMage});
    background-color: white;
    border-radius: 10px;
background-color:  rgb(1, 3, 3); /* Header background color */
    /* Header background color */
  }

  .ant-modal-header {
    color: black; /* Header text color */
    border: none;
    border-radius: 10px 10px 0 0; /* Rounded corners for the header */
  }

  .ant-modal-title {
    color: black;
    font-size:1rem;
  }
`;

const FormContainer = styled.div`
 background-image:url(${ModalIMage});
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff; /* Text color */
`;

const ResponsiveTypography = styled(Typography)`
  font-size: 1em;
  font-weight: bolder;
  color: #fff; /* Text color */
`;

const ContactModal = ({ visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
        form.resetFields();
    };

    return (
        <CustomModal visible={visible} onCancel={onCancel} footer={null} centered closable>
            <ResponsiveTypography>Book your appointment now through the website and get 30% off</ResponsiveTypography>
            <FormContainer>
                <ResponsiveTypography>Share your details with us, and we'll take care of the rest.</ResponsiveTypography>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Please enter your name' }]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                        name="phoneNumber"
                        rules={[
                            { required: true, message: 'Please enter your phone number' },
                            {
                                pattern: /^\d{10}$/,
                                message: 'Phone number must be 10 digits',
                            },
                        ]}
                    >
                        <Input placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" style={{ background: ' rgb(163, 3, 3)', color: 'white' }}>
                            Submit
                        </Button>
                        <Button onClick={onCancel} style={{ marginLeft: '10px', color: 'white', background: ' rgb(163, 3, 3)', }}>
                            Close
                        </Button>
                    </Form.Item>
                </Form>
            </FormContainer>
        </CustomModal>
    );
};

export default ContactModal;
