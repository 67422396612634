import React from "react";
import { Row, Col, Typography } from "antd";
import aboutImage1 from "../../assets/images/carwashhero.jpg";
import aboutImage from "../../assets/images/cwhero.png";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";


const Container = styled.div`
  padding: 20px;
  text-align: center;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image:url(${aboutImage1});
  background-size:cover ;
`;

const ContentWrapper = styled.div`
  max-width: 100%;
  background:rgb(255,255,255,0.7)
`;

const Title = styled.h2`
color:white;
  font-size: 2rem;
  margin-bottom: 20px;
color:black;
@media (max-width: 600px) {
  font-size: 1.4rem; /* Smaller font size for small screens */
`;

const Image = styled.img`
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const TextContainer = styled.div`
  text-align: center;
  justify-item: center;

`;

const Text = styled(Typography.Paragraph)`
align-text:left;
font-family: 'Patua One', sans-serif;
padding:20px;
color:black;
  font-size: 1.3rem;
  line-height: 1.6;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    font-size: 1rem; /* Smaller font size for small screens */
`;

const imageVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      delay: 0.2,
      ease: "easeOut",
    },
  },
};

const textVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      delay: 0.4,
      ease: "easeOut",
    },
  },
};

function About() {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  return (
    <Container>
      <ContentWrapper>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={10} lg={10}>
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={imageVariants}
            >
              <Image src={aboutImage} alt="About Us" />
            </motion.div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={textVariants}
            >
              <TextContainer>
                <Title>About Us</Title>
                <Text>
                  At CJ Car Wash, we take pride in providing the best car cleaning
                  and detailing services in town. With years of experience and a
                  dedicated team of professionals, we ensure that your vehicle
                  receives the highest level of care.
                </Text>
                <Text>
                  Our mission is to make your car look and feel brand new. Whether
                  it's a thorough exterior wash, interior detailing, waxing, or tire
                  care, we offer a wide range of services to meet your car care
                  needs.
                </Text>
                <Text>
                  We use state-of-the-art equipment and high-quality products to
                  achieve outstanding results. Your satisfaction is our top
                  priority, and we guarantee that your car will leave our facility
                  looking its best.
                </Text>
              </TextContainer>
            </motion.div>
          </Col>

        </Row>
      </ContentWrapper>
    </Container>
  );
}

export default About;
